import $ from 'jquery'
import * as FilePond from 'filepond';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';

import { getCookie } from '@/utils/cookies'

$(document).ready(onReady)

function onReady() {
  FilePond.registerPlugin(FilePondPluginImagePreview, FilePondPluginFilePoster)

  $('[data-filepond]').each(function (index, el) {
    const filepond = FilePond.create(el)
    const $el =  $(el)
    const target = $el.data('filepond-target')
    const preview = $el.data('filepond-preview')

    filepond.setOptions({
      server: {
        url: '/images',
        method: 'POST',
        process: {
          headers:{
            'Authorization': getCookie('backme_x_backreceipt_token')
          },
          onload: (response) => {
            const res = JSON.parse(response)
            const target = $(el).data('filepond-target')
            $(target).val(res.id)
          },
          onerror: (response) => {
            console.log(response)
          }
        },
        revert: (uniqueFileId, load, error) => {
          $(target).val('')
          load()
        },
        load: null,
      },
      filePosterMaxHeight: '256'
    })

    if (!!preview)  {
      filepond.files = [{
        options: {
          type: 'local', // 不加這個的話，會自動再一次上傳圖片
          file: {
            type: null // 很怪的是一定要這個參數，不然沒辦法顯示圖片
          },
          metadata: {
              poster: preview
          }
        }
      }]
    }
  })
}
