import $ from 'jquery'
const DATA_KEY = 'br.csv-file-reader'

class CSVFileReader {
  constructor(element, event) {
    this.reader = new FileReader()

    this.$elem = $(element)
    this.$target = $(this.$elem.data('csv-file-reader-target'))
  }

  // ==================
  //   Public Methods
  // ==================

  read(file) {
    this.reader.addEventListener('loadend', (e) => {
      this.csv = this.convertCSV(e.target.result)
      this.json = JSON.stringify(this.csv)
      this.write()
    })

    this.reader.readAsText(file)
  }

  convertCSV(csv) {
    const sanitized = csv.replace(/[\r]/g, '') // remove windows line breaks
    const lines = sanitized.split('\n')
    const result = []
    const headers = lines[0].split(',')

    for (let i = 1; i < lines.length; i++) {
      // skip empty lines
      if (lines[i] === '') {
        continue
      }

      const obj = {}
      const currentLine = lines[i].split(',')

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j]
      }

      result.push(obj)
    }

    return result
  }

  write() {
    this.$target.val(this.json)
  }

  // ==================
  //   Static Methods
  // ==================

  static get DATA_KEY() {
    return DATA_KEY
  }

  static singletonInterface(element, event) {
    let instance = $(element).data(DATA_KEY)
    if (!instance) {
      instance = new CSVFileReader(element, event)
    }

    return instance
  }
}

$(document).on('change', '[data-csv-file-reader]', (event) => {
  const element = event.currentTarget
  const file = event.target.files[0]

  const singleton = CSVFileReader.singletonInterface(element)
  singleton.read(file)
})

export default CSVFileReader
